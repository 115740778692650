// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import {Link} from 'react-router-dom';
// -----------------------------------------------------------------------------

class Header extends React.Component{
    constructor(props){
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
        this.menuClick = this.menuClick.bind(this);
    }
    menuClick(e){
        e.stopPropagation();
        const elems = document.querySelectorAll('nav a');
        [].forEach.call(elems, function(el){
            el.classList.remove('current');
        })
        e.target.classList.add('current');
        if(window.innerWidth <= 768 ){
            document.getElementById('menu').style.display = 'none';
        }
    }
    clickHandler(){
        if(window.innerWidth <= 768 ){
            document.getElementById('menu').style.display = 'block';
        }
    }
    render(){
        return(
            <header id="top">
            	<h1><Link to="/">Patrick Dobson</Link></h1>
                <h2>Web Developer</h2>
                <p>(07833) 184038 <span className="mid">|</span><a href="mailto:pat@patrickdobson.co.uk?subject-Contact%20from%20patrickdobson.co.uk">pat@patrickdobson.co.uk</a></p>
            	<nav onClick={this.clickHandler}>
                	<ul id="menu">
                    	<li><Link to="/about" onClick={this.menuClick}>About</Link></li>
                    	<li><Link to="/cv" onClick={this.menuClick}>CV</Link></li>
                    	<li><Link to="/portfolio" onClick={this.menuClick}>Portfolio</Link></li>
                    	<li><Link to="/testimonials" onClick={this.menuClick}>Testimonials</Link></li>
                    	<li><Link to="/contact" onClick={this.menuClick}>Contact</Link></li>
            			<li><Link to="/" onClick={this.menuClick} className="current">Home</Link></li>
                    </ul>
                </nav>
            </header>
        )
    }
}

export default Header;
