// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Home extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <p>I have been developing web sites and web applications since 2000. I have recently been working freelance but have, in the past worked as part of a development team in both front end technologies and Coldfusion. My skillset is as follows:</p>

                <div className="hList">
                    <p>Major:</p>
                    <ul>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>SASS / SCSS</li>
                        <li>Javascript</li>
                        <li>jQuery</li>
                        <li>PHP</li>
                        <li>SQL/MySQL</li>
                        <li>jQuery validator</li>
                        <li>Cross browser compliance</li>
                        <li>Customer Facing Skills</li>

                    </ul>
                </div>

                <div className="hList">
                    <p>Minor:</p>
                    <ul>
                        <li>AJAX</li>
                        <li>SEO</li>
                        <li>JSON</li>
                        <li>Photoshop</li>
                        <li>OO Javascript</li>
                        <li>Responsive / mobile</li>
                        <li>Jest</li>
                        <li>React</li>
                        <li>Wordpress</li>
                        <li>JSP</li>
                        <li>ES6</li>
                        <li>Handlebars</li>
                        <li>Highcharts</li>
                    </ul>
                </div>

                <div className="hList">
                    <p>Strong awareness of:</p>
                    <ul>
                        <li>Accessibility (DDA etc.)</li>
                        <li>Usability</li>
                        <li>XML</li>
                        <li>SVN</li>
                        <li>GIT</li>
                        <li>Agile</li>
                        <li>Relational Database Design</li>
                        <li>Eclipse</li>
                        <li>Bootstrap (2/3)</li>
                        <li>SFCC</li>
                    </ul>
                </div>

                <p className="cBoth">In my role as a freelance web developer I have been involved in the entire life cycle of web development. This includes initial contact with the customer, scoping the project, producing design documentation, designing &amp; developing the website/application and going &lsquo;live&rsquo;.</p>
                <p className="contractLinks">Recent contracts include <a href="https://www.halfords.com/" target="_blank">Halfords</a>, <a href="https://www.petsathome.com/" target="_blank">Pets at home</a>, BMW, <a href="https://www.myvouchercodes.co.uk/" target="_blank">MyVouchercodes / GoCompare</a>, <a href="http://www.pru.co.uk" target="_blank">Prudential</a>, <a href="https://www.bbr.com/" target="_blank">Berry Brothers &amp; Rudd</a>, <a href="https://www.ibanet.org/" target="_blank">International Bar Association</a></p>

                <p className="stackexchange">
                    <a href="https://stackexchange.com/users/3140572/pat-dobson" target="_blank">
                        <img src="https://stackexchange.com/users/flair/3140572.png" width="208" height="58" alt="profile for Pat Dobson on Stack Exchange, a network of free, community-driven Q&amp;A sites" title="profile for Pat Dobson on Stack Exchange, a network of free, community-driven Q&amp;A sites" />
                    </a>
                </p>
            </>
        )
    }
}

export default Home;
