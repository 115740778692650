// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactTel: '',
            message: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(e){
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }
    handleSubmit(e){
        e.preventDefault();
        sendEmail(this.state);
    }
    render(){
        return(
            <>
                <h1>Contact Me</h1>

                <p>
                    <span className="phone">(07833) 184038</span>
                    <span className="email">
                        <a href="mailto:pat@patrickdobson.co.uk?subject=Contact%20From%20PatrickDobson.co.uk" title="Contact Patrick Dobson">
                            pat@patrickdobson.co.uk
                        </a>
                    </span>
                </p>

                <div id="sentMessage">
                    <h2>Your message has been sent.</h2>
                    <p>Thank you for contacting me.</p>
                    <p>I will be in touch soon . . .</p>
                    <p>If your enquiry is urgent, please call me on (07833) 184038</p>
                </div>

                <form name="contactForm" id="contactForm" method="post" onSubmit={this.handleSubmit}>

                    <p>
                        <label htmlFor="contactName">Name:</label>
                        <input type="text" name="contactName" id="contactName" onChange={this.handleChange} value={this.state.contactName} /><span id="nameError" className="errorSpan">&Phi;</span>
                    </p>
                    <p>
                        <label htmlFor="contactEmail">Email:</label>
                        <input type="text" name="contactEmail" id="contactEmail" onChange={this.handleChange} value={this.state.contactEmail} /><span id="emailError" className="errorSpan">&Phi;</span>
                    </p>
                    <p>
                        <label htmlFor="contactTel">Telephone:</label>
                        <input type="text" name="contactTel" id="contactTel" onChange={this.handleChange} value={this.state.contactTel} />
                    </p>
                    <p>
                        <label htmlFor="message">Message:</label>
                        <textarea name="message" id="message" onChange={this.handleChange} value={this.state.message}></textarea>
                    </p>
                    <span className="errorSpan">&Phi; - required information</span>
                    <p>
                        <input type="submit" value="Send message" id="submit" />
                    </p>

                </form>
            </>
        )
    }
}

function sendEmail(formContents){
    const formData = [{
        contactName: formContents.contactName,
        contactEmail: formContents.contactEmail,
        contactTel: formContents.contactTel,
        message: formContents.message
    }];
    let sendEmail = false;
    document.getElementById('nameError').innerHTML = '&Phi;';
    document.getElementById('emailError').innerHTML = '&Phi;';
    if( formContents.contactName !== '' && formContents.contactEmail !== '' ){
        sendEmail = true;
    }else{
        if( formContents.contactName === '' ){
            document.getElementById('nameError').innerHTML = 'Please enter a name';
        }
        if( formContents.contactEmail === '' ){
            document.getElementById('emailError').innerHTML = 'Please enter an email';
        }
    }
    if(sendEmail){
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function(){
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                document.getElementById('contactForm').style.display = 'none';
                document.getElementById('sentMessage').style.display = 'block';
            }
            if(xmlhttp.readyState == 4 && xmlhttp.status == 405){
                document.getElementById('contactForm').style.display = 'none';
                document.getElementById('emailError').style.display = 'block';
            }
        }
        xmlhttp.open('POST', 'contactEmail.php');
        xmlhttp.send(JSON.stringify(formData));
    }
}

export default Contact;
