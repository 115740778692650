// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import {Link} from 'react-router-dom';
// -----------------------------------------------------------------------------

class Footer extends React.Component{
    constructor(props){
        super(props);
        this.linkClick = this.linkClick.bind(this);
    }
    linkClick(){
        const elems = document.querySelectorAll('nav a');
        [].forEach.call(elems, function(el){
            el.classList.remove('current');
        })
    }
    render(){
        const year = new Date().getFullYear();
        return(
            <footer>
            	<p>All content &copy;{year} Patrick Dobson</p>
            	<p>
                    <Link to="/terms" onClick={this.linkClick}>Terms and conditions</Link><br />
                    <Link to="/privacy" onClick={this.linkClick}>Privacy &amp; Cookies</Link>
                </p>
            </footer>
        )
    }
}

export default Footer;
