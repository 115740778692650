// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Privacy extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <h1>Privacy &amp; Cookies</h1>

                <h2>Privacy Policy</h2>

                <ul>
                    <li>We are committed to protecting your privacy. We will only use the information that we collect about you lawfully (in accordance with the Data Protection Act 1998).</li>
                    <li>We only collect information about you for the purpose of direct contact to answer enquiries or aid in the completion of work.</li>
                    <li>We will not e-mail you in the future unless you have given us your consent. Consent is inferred when you contact us.</li>
                    <li>The type of information we may collect about you includes:
                        <ul>
                            <li>Your name</li>
                            <li>Your telephone number</li>
                            <li>Your email address</li>
                        </ul>
                    </li>
                    <li>We will never collect sensitive information about you without your explicit consent.</li>
                    <li>Any personal information which we hold will be held securely in accordance with our internal security policy and the law.</li>
                    <li>If we intend to transfer your information outside the EEA (European Economic Area) we will always obtain your consent first.</li>
                    <li>We use technology to track the patterns of behaviour of visitors to our site. See below for further details of the Cookies usage on this site.</li>
                    <li>We will never sell or transfer your information to a third party.</li>
                </ul>

                <h2>Cookies</h2>

                <h3>What are cookies?</h3>

                <p>‘Cookies’ are small pieces of information that a website sends to your computer’s hard drive while you are viewing a website.</p>

                <h3>How does this site use cookies ?</h3>

                <ul>
                    <li><strong>Tracking/user analysis cookies</strong> : These allow us to see how many people are visiting this site and uses Google Analytics which sets a cookie on your computer.</li>
                    <li><strong>System cookies</strong> : These allow our website to remember any options that you, as a user set. This may include your language preferences, a shopping basket or a colour scheme.</li>
                </ul>
            </>
        )
    }
}

export default Privacy;
