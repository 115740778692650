// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import ReactDOM from 'react-dom';
    import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
    import styles from './style/style';
    const appRoot = document.getElementById('app');
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Header from './components/Header';
    import Home from './components/Home';
    import About from './components/About';
    import Cv from './components/Cv';
    import Prudential from './components/Prudential';
    import Dyson from './components/Dyson';
    import VerseOne from './components/VerseOne';
    import Portfolio from './components/Portfolio';
    import Testimonials from './components/Testimonials';
    import Contact from './components/Contact';
    import Terms from './components/Terms';
    import Privacy from './components/Privacy';
    import Footer from './components/Footer';
// -----------------------------------------------------------------------------


class App extends React.Component{
    constructor(props){
        super(props);
        this.state = { page: 'home' };
    }
    windowResize(){
        if(window.innerWidth > 768 ){
            document.getElementById('menu').style.display = 'block';
        }else{
            document.getElementById('menu').style.display = 'none';
        }
    }
    componentDidMount(){
        window.addEventListener('resize', this.windowResize);
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.windowResize);
    }
    render(){
        return(
            <>
                <Header />
                <div className="content">
                    <Switch>
                        <Route exact path="/"><Home /></Route>
                        <Route exact path="/about"><About /></Route>
                        <Route exact path="/cv"><Cv /></Route>
                        <Route exact path="/prudential"><Prudential /></Route>
                        <Route exact path="/dyson"><Dyson /></Route>
                        <Route exact path="/verseone"><VerseOne /></Route>
                        <Route exact path="/portfolio"><Portfolio /></Route>
                        <Route exact path="/testimonials"><Testimonials /></Route>
                        <Route exact path="/contact"><Contact /></Route>
                        <Route exact path="/terms"><Terms /></Route>
                        <Route exact path="/privacy"><Privacy /></Route>
                    </Switch>
                </div>
                <Footer />
            </>
        )
    }
}

// -- Render -------------------------------------------------------------------
    ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, appRoot )
// -----------------------------------------------------------------------------
