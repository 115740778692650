// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Testimonials extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
            <h1>Testimonials</h1>

                 {/* ---  Office star  --- */}
                <blockquote>
                    &ldquo;We found Patrick by searching on the internet and after an initial meeting with him and a recommendation from someone else, we decided to go ahead with the build of our new website.<br />
                    Patrick is very knowledgeable in the subject but didn't talk "techi" speak, and if He did, He was quick to explain to ordinary folk what they meant!<br />
                    All instructions were dealt with quickly and efficiently and nothing seemed too much trouble, for what seemed a very complicated job to us and involved them speaking with a third party in our industry to gain further information.<br />
                    Patrick went over and above our original expectations of the job specification and I would be happy to recommend Patrick to other businesses.
                    Thank you very much for all your hard work Patrick.&rdquo;<br />
                    <a href="http://www.officestar-group.com" target="_blank">Sally Harrison - Office Star Group</a><br />
                </blockquote>

                 {/* ---  Right height  --- */}
                <blockquote>
                    &ldquo;I have found the whole experience of dealing with Patrick very refreshing. Pat is a smashing chap to deal with and takes the time to listen and understand what it is your trying to create whilst providing professional advice and guidance on design and costs to help you achieve your goal of creating a site that reflects what you, your company and its services can provide.<br /><br />
                    I am very pleased to recommend Patrick.&rdquo;<br />
                    <a>James O'Grady - Right Height Access Services</a><br />
                </blockquote>

                 {/* ---  Eco-camping  --- */}
                <blockquote>
                    &ldquo;Patrick has developed our website for an eco camping holiday site, although we have only started the business over the last couple of months we have received a lot of interest via the website, and great comments from users.<br />
                    Pat is not just a web designer he is also committed to providing a good, friendly and expedient service, and cares about sustainability issues.<br />
                    I have also worked with Patrick on an environmental technical toolkit application which was very complex and required a high level of programming knowledge.<br />
                    Always very supportive as we are not super IT literate, keeps everything clear and user friendly.&rdquo;<br /><br />
                    <a href="http://www.eco-camping.co.uk" target="_blank">Jane and Martin North Lodge Eco Holidays</a><br />
                </blockquote>

                 {/* ---  Personal summits  --- */}
                <blockquote>
                    &ldquo;Patrick has helped me wonderfully move from an idea in my head to a reality online. Pat has always been very helpful and accommodating. The quality of his work is always spot on. Very happy to recommend Patrick every time.&rdquo;<br />
                    <a href="http://www.personalsummits.com" target="_blank">Richard Bisiker - Personal Summits</a><br />
                </blockquote>

                 {/* ---  Seize the day  --- */}
                <blockquote>
                    &ldquo;The people I choose to work with in creating our music and getting our message across need to know where I'm coming from.<br />I want someone who genuinely cares about the world and the people in it. Pat does! He's efficient, intelligent and a great guy...&rdquo;<br />
                    <a href="http://www.seizetheday.org" target="_blank">Seize The Day</a><br />
                </blockquote>

                 {/* ---  Sanderling  --- */}
                <blockquote>
                    &ldquo;Patrick has created us a fantastic website,  that has enabled us to take full control of our business of holiday lettings, with a brilliant on-line booking system and easy to use administration even for those of us with limited pc skills.<br />
                    <a href="http://www.sanderlingcottages.co.uk" target="_blank">Anne Whittle - Sanderling Holiday Cottages</a>
                    <br />
                </blockquote>

                 {/* ---  Franklyn Nevard  --- */}
                <blockquote>
                    &ldquo;I was recommended to Patrick by a friend, and I must say that I have not been disappointed, the ideas, enthusiasm and energy that has emanated from Patrick has assisted with the whole nightmare of web design and the connection to the www, I could not  now be without him for all of my internet activities.<br />
            Thank you Patrick.&rdquo;<br />
                    <a href="http://www.franklynnevard.co.uk" target="_blank">Franklyn Nevard - Franklyn Nevard Associates</a><br />
                </blockquote>

                 {/* ---  Lix Roe-French  --- */}
                <blockquote>
                    &ldquo;Patrick has been really great and I very much appreciate his work on our behalf !&rdquo;<br /><br />
                    <a href="http://www.lizroe-french.co.uk" target="_blank">Liz Roe-French, Astrologer/Healer Kingsbridge Devon.</a>
                    <br />
                </blockquote>

                 {/* ---  Dave King  --- */}
                <blockquote>
                    &ldquo;We at Dave King fireplaces would like to say that since we have had Patrick re-develop our web site, we have had more enquiries than in the whole time the previous web page was on line. Excellent service. Well Done.&rdquo;<br />
                    <a href="http://www.davekingfireplaces.co.uk" target="_blank">Dave King Fireplaces</a>
                    <br />
                </blockquote>

                 {/* ---  David Snowdon  --- */}
                <blockquote>
                    &ldquo;Patrick was really helpful from the start, with a professional, friendly and reliable service. Its really good to be able to edit my own site thanks to his excellent training.&rdquo;<br /><br />
                    <a href="http://www.davidsnowdon.co.uk" target="_blank">David Snowdon - David Snowdon Furniture</a>
                    <br />
                </blockquote>
            </>
        )
    }
}

export default Testimonials;
