// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Portfolio extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <h1>Portfolio</h1>

                <div className="quickLinks">
                    <a href="#ynnisirgar" className="scroll"><img src="/images/portfolio/ynnisirgar.jpg" alt="Ynni Sir Gar" /></a>
                    <a href="#ttalk" className="scroll"><img src="/images/portfolio/ttalkltd.jpg" alt="Ttalk Ltd" /></a>
                    <a href="#broadstone" className="scroll"><img src="/images/portfolio/broadstonerarebreeds.jpg" alt="Broadstone Rare Breeds" /></a>
                    <a href="#dandc" className="scroll"><img src="/images/portfolio/devonandcornwallflockbook.jpg" alt="Devon and Cornwall flockbook association" /></a>
                    <a href="#ptbinfo" className="scroll"><img src="/images/portfolio/ptbInfo.jpg" alt="Park that bike info site" /></a>
                    <a href="#journeyman" className="scroll"><img src="/images/portfolio/journeyman.jpg" alt="Journeyman" /></a>
                    <a href="#davidSnowdon" className="scroll"><img src="/images/portfolio/davidSnowdon.jpg" alt="David Snowdon" /></a>
                    <a href="#ecoCamping" className="scroll"><img src="/images/portfolio/ecoCamping.jpg" alt="Eco Camping" /></a>
                    <a href="#northLodge" className="scroll"><img src="/images/portfolio/northLodge.jpg" alt="North lodge" /></a>
                    <a href="#ptbMain" className="scroll"><img src="/images/portfolio/ptbCom.jpg" alt="Park that bike main site" /></a>
                  </div>

                  {/* ---  YnniSirGar - --- */}
                 <div className="siteSample" id="ttalk">
                         <a name="ynnisirgar"></a>
                     <img src="/images/portfolio/ynnisirgar.jpg" alt="Ynni Sir G&acirc;r" />
                       <h2>Ynni Sir G&acirc;r</h2>
                       <p>Ynni Sir G&acirc;r is a website showcasing the work of Carmarthenshire energy.</p>
                       <p>The website offers the following features:</p>
                     <ul>
                         <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                         <li>A dual language site with both Welsh and English content</li>
                         <li>Reacreated from an old CMS version of the site</li>
                     </ul>
                       <a href="https://www.ynnisirgar.org/" className="siteLink" target="_blank">View site</a>
                     <a href="#top" className="upLink scroll">Top of page</a>
                   </div>

                  {/* ---  Ttalk Ltd - --- */}
                 <div className="siteSample" id="ttalk">
                         <a name="ttalk"></a>
                     <img src="/images/portfolio/ttalkltd.jpg" alt="Ttalk Ltd" />
                       <h2>Ttalk Ltd</h2>
                     <p>Ttalk Ltd is a training company offering technical training.</p>
                       <p>The website offers the following features:</p>
                     <ul>
                         <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                         <li>Written in React</li>
                     </ul>
                       <a href="https://ttalkltd.co.uk/" className="siteLink" target="_blank">View site</a>
                     <a href="#top" className="upLink scroll">Top of page</a>
                   </div>

                 {/* ---  Broadstone Rare Breeds - --- */}
                <div className="siteSample" id="broadstone">
                        <a name="broadstone"></a>
                    <img src="/images/portfolio/broadstonerarebreeds.jpg" alt="Broadstone Rare Breeds" />
                      <h2>Broadstone Rare Breeds</h2>
                    <p>Broadstone Rare Breeds is a farm based in Pembrokeshire, Wales.</p>
                      <p>The website offers the following features:</p>
                    <ul>
                        <li>Easily updatable via a JSON file</li>
                        <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                        <li>Integration of Etsy products via the Etsy API</li>
                          <li>Written in React</li>
                    </ul>
                      <a href="https://www.broadstonerarebreeds.co.uk" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  Devon and Cornwall flockbook - --- */}
                <div className="siteSample" id="dandc">
                        <a name="dandc"></a>
                    <img src="/images/portfolio/devonandcornwallflockbook.jpg" alt="Devon and Cornwall longwool association" />
                      <h2>Devon and Cornwall longwool association</h2>
                    <p>The Devon and Cornwall longwool association is the official body responsible for ensuring the future of this sheep breed.</p>
                      <p>The website offers the following features:</p>
                    <ul>
                        <li>A full content management system</li>
                        <li>Members only facilities</li>
                        <li>Members only facilities include editing own profile, adding 'for sale' items, association newsletters, useful information and lamb birth registration</li>
                        <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                        <li>A show calendar with website and map links</li>
                        <li>A 'for sale' section where members can place classified adverts</li>
                        <li>A list of breeders with links for email, website, facebook and twitter</li>
                    </ul>
                      <a href="http://www.devonandcornwallflockbook.co.uk" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  PTB info - --- */}
                <div className="siteSample" id="ptbinfo">
                        <a name="ptbinfo"></a>
                    <img src="/images/portfolio/ptbInfo.jpg" alt="Park that bike info site" />
                      <h2>Park That Bike - public site</h2>
                      <p>ParkThatBike is an independent transport consultancy that works with local authorities, government agencies and major employers.</p>
                      <p>The website features:</p>
                      <ul>
                        <li>A content management system allowing the site owner to update the site</li>
                        <li>A completely responsive design, optimised for both desktop viewing and mobile</li>
                        <li>Bold full screen image backgrounds</li>
                        <li>An interactive multipart application process allowing visitors to apply for free cycle parking</li>
                        <li>Social media integration</li>
                        <li>A full online store integrated with PayPal to provide secure shopping</li>
                    </ul>

                      <a href="http://www.parkthatbike.info/" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  Journeyman - --- */}
                <div className="siteSample" id="journeyman">
                        <a name="journeyman"></a>
                    <img src="/images/portfolio/journeyman.jpg" alt="Journeyman" />
                      <h2>Journeyman Services</h2>
                      <p>Journeyman Services are an ex-pat insurance company based in the Forest of Dean.</p>
                      <p>The website features:</p>
                      <ul>
                        <li>An interactive AJAX based travel insurance quotation engine</li>
                          <li>Integration with SagePay for online billing</li>
                          <li>Downloads section</li>
                          <li>High-graphical yet quick loading design which reflects the company brand</li>
                      </ul>

                      <a href="http://www.journeyman-services.com/" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  David Snowdon - --- */}
                <div className="siteSample" id="davidSnowdon">
                        <a name="davidSnowdon"></a>
                    <img src="/images/portfolio/davidSnowdon.jpg" alt="David Snowdon" />
                      <h2>David Snowdon Furniture</h2>
                      <p>David Snowdon is a bespoke furniture maker in the Forest of Dean with over 27 years experience of fine furniture manufacture.</p>
                      <p>The website showcases David's work via a number of image galleries and also offers a contact form and a page for client testimonials.</p>
                      <p>A 'slide-in' gallery and random images add some movement to the front page.</p>
                      <p>The site was written in such a way as to make it easy for the customer to keep it up to date.</p>

                      <a href="http://www.davidsnowdon.co.uk/" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  Eco camping - --- */}
                <div className="siteSample" id="ecoCamping">
                        <a name="ecoCamping"></a>
                    <img src="/images/portfolio/ecoCamping.jpg" alt="Eco Camping" />
                      <h2>North lodge eco camping</h2>
                      <p>North lodge eco camping is a 'green', sustainable campsite and B&amp;Bin South West Wales.</p>
                      <p>The website offers the following features:</p>
                      <ul>
                          <li>Easy to use contact form.</li>
                          <li>Link to a personalised 'google' map.</li>
                          <li>Original design.</li>
                          <li>Fully responsive - ready for tablet and mobile</li>
                      </ul>

                      <a href="http://www.eco-camping.co.uk/" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  Liz roe french - --- */}
                <div className="siteSample" id="lrf">
                        <a name="lrf"></a>
                    <img src="/images/portfolio/lizRoeFrench.jpg" alt="Liz Roe French" />
                      <h2>Liz Roe French</h2>
                      <p>Liz Roe-French is an astrologer with 30 years experience in the field. She gives astrological reading, runs workshops and presents seminars.</p>
                      <p>The website offers her a ᳨op window⠡nd central point of contact on the web.</p>
                      <p>Included within the site are various ᤯wnload⠡reas where customers can download newsletters and case studies.</p>

                      <a href="http://www.lizroe-french.co.uk/" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  Northlodge - --- */}
                <div className="siteSample" id="northLodge">
                        <a name="northLodge"></a>
                    <img src="/images/portfolio/northLodge.jpg" alt="North lodge" />
                      <h2>North Lodge</h2>
                      <p>North Lodge is an eco-friendly B&amp;B in South West Wales.</p>
                      <p>The website features:</p>
                      <ul>
                        <li>A fresh design which reflects the 'green' ethos of the site owners</li>
                          <li>A 'lightbox' based image gallery</li>
                          <li>An easy to use contact form</li>
                          <li>Bold and easy to read contact details on every page</li>
                      </ul>

                      <a href="http://www.northlodgewales.co.uk/" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                 {/* ---  PTB com - --- */}
                <div className="siteSample" id="ptbMain">
                        <a name="ptbMain"></a>
                    <img src="/images/portfolio/ptbCom.jpg" alt="Park that bike main site" />
                      <h2>Park That Bike - corporate site</h2>
                      <p>Park That Bike are a UK based company who specialise in parking solutions for bicycles.</p>
                      <p>The website offers the following features:</p>
                      <ul>
                        <li>A content management system allowing the site owner to update the site</li>
                        <li>A completely responsive design, optimised for both desktop viewing and mobile</li>
                        <li>Bold full screen image backgrounds</li>
                        <li>An image gallery 'slider' which gives access to a large amount of images in a small space</li>
                        <li>Social media integration</li>
                        <li>A news section which draws its content from the CMS</li>
                    </ul>

                      <a href="http://www.parkthatbike.com/" className="siteLink" target="_blank">View site</a>
                    <a href="#top" className="upLink scroll">Top of page</a>
                  </div>

                <p className="cBoth"></p>
            </>
        )
    }
}

export default Portfolio;
