// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import {Link} from 'react-router-dom';
// -----------------------------------------------------------------------------

class VerseOne extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <h1>Verse One portfolio</h1>

                <p>During my time at Verse One I was primarily involved in updating legacy sites to the latest version of the Verse One CMS. This involved working from either an original photoshop document or recreating the site from the existing legacy version. All Verse One sites are based upon a bespoke content management system.</p>
                <p>Please click on a thumbnail to view the site.</p>

                <Link to="/cv" className="leftArrow">Back to CV</Link>

                <div className="portfolio verseOne">
                    <a href="http://www.firstwessex.org/main.cfm" target="_blank"><img src="/images/portfolio/verseOne/firstWessex.jpg" alt="First Wessex housing association" /></a>
                    <a href="http://www.midyorks.nhs.uk/main.cfm" target="_blank"><img src="/images/portfolio/verseOne/midYorks.jpg" alt="Mid Yorks NHS" /></a>
                    <a href="http://www.orbit.org.uk/main.cfm" target="_blank"><img src="/images/portfolio/verseOne/orbit.jpg" alt="Orbit housing association" /></a>
                    <a href="http://www.severnsidehousing.co.uk/main.cfm" target="_blank"><img src="/images/portfolio/verseOne/severnside.jpg" alt="Severnside housing association" /></a>
                    <a href="http://www.synergyhousing.co.uk/main.cfm" target="_blank"><img src="/images/portfolio/verseOne/synergy.jpg" alt="Synergy housing association" /></a>
                    <a href="http://www.tristarhomes.co.uk/main.cfm" target="_blank"><img src="/images/portfolio/verseOne/tristar.jpg" alt="Tristar housing association" /></a>
                </div>

                <Link to="/cv" className="leftArrow">Back to CV</Link>
            </>
        )
    }
}

export default VerseOne;
