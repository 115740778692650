// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class About extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <p>I started my working career with a full engineering apprenticeship based in mechanical and electrical engineering with Simon Barron. I then progressed into panel wiring and electronic assembly/prototyping.</p>

                <p>I trained as an FPGA design engineer with Serco. And continued to develop my skills with Open University courses in:</p>

                <ul>
                    <li>Foundation technology</li>
                    <li>Analogue &amp; Digital Electronics</li>
                    <li>Logic Design</li>
                    <li>Mathematics</li>
                </ul>

                <p>In early 2000 I then moved into Web development and started working with HTML, Photoshop, Flash &amp; Dreamweaver</p>

                <p>In 2002 I began working in Coldfusion as a back-end technology and expanded my development skills to include Javascript, SQL &amp; CSS</p>

                <p>I developed these skills through on the job learning and through University courses:</p>

                <ul>
                    <li>Relational Database Design (Open University)</li>
                    <li>Multimedia Design (Gloucestershire University)</li>
                    <li>Flash (Gloucestershire University)</li>
                    <li>PHP(Gloucestershire University)</li>
                    <li>IT Law (Gloucestershire University)</li>
                </ul>

                <p>Through my studies I gained a HNC in Multimedia Information Technology from Gloucestershire University and a Bsc from the Open University.</p>

                <p>Up until 2006 I worked for Serco as a web developer using Coldfusion, XHTML, CSS, SQL, Photoshop &amp; Javascript. I also produced the technical documentation to support the developments. I have experience in source control software (MKS Source Integrity) and large scale developments working as part of a front end development team. I have played a part in the development of both Internet and Intranet sites for major law enforcement agencies working as part of a small Coldfusion development team.</p>

                <p>Since 2006 I have worked as a freelance web developer where I have been involved in the entire lifecycle development of websites using various combinations of my skill set.</p>
            </>
        )
    }
}

export default About;
