// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Terms extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
            <h1>Terms and conditions of website use</h1>

            <ol>
                <li>By accessing this Website, you confirm that you are eligible (for example, a person of at least 16 years) to use this Website and you agree to be bound by all of the following terms and conditions of use (the "Terms"). These Terms govern your access to the Website except where there are separate terms and conditions relevant to particular areas of this Website, which are indicated on the appropriate Website page. If you do not agree to abide by these Terms then you should not use the Website.</li>
                <li>For clarity, in these Terms, references to "we", "us", "our" and "ourselves" are references to Patrick Dobson.</li>
                <li>Unless otherwise specified, the materials on this Website are directed solely at users who access this Website from the United Kingdom.</li>
                <li>All material on this Website including any advertising and/or promotional idents, images, text and/or audio is our property or has been licensed to us. All trade marks, names, logos and other intellectual property rights existing in this Website are also owned or licensed by us. You may use this Website for your personal information and you may make a copy of the pages of this Website but only for your personal non-commercial use, provided that you keep all copyright and other proprietary notices intact. Any modification, transmission, hiring, copying (other than for non-commercial personal use), reusing or otherwise using the content of this Website for public or commercial purposes is prohibited.</li>
                <li>Whilst we have worked diligently to provide accurate and complete information, we cannot be liable to any person for any loss or damage which may arise directly or indirectly from the use of this Website (or any inability to use it) or any of the information contained on this Website. This Website and the information and material which it contains are subject to change without notice. All warranties whether express or implied as to the accuracy or completeness of the information contained on this Website or in respect of any materials or products referred to on this Website are hereby excluded to the fullest extent permitted by law.</li>
                <li>Links on this Website and may lead to third party websites. The content, accuracy and function of such websites is outside of our control and we cannot accept any responsibility for them and nor do we endorse the contents of such third party websites. In particular, any dealings that you have with such third party website operators shall be on the terms and conditions (if any) of that website operator.</li>
                <li>Any communication or material which you transmit to us through this Website or otherwise via the Internet will be treated as non-confidential unless such communication or material includes personal information, which we will use in accordance with standard Privacy Policy guidelines.</li>
            </ol>
            </>
        )
    }
}

export default Terms;
