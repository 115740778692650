// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import {Link} from 'react-router-dom';
// -----------------------------------------------------------------------------

class Prudential extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <h1>Prudential portfolio</h1>

                <Link to="/cv" className="leftArrow">Back to CV</Link>

                <p>For my role in Prudential I worked in a tight knit team of three developers. Our main work was developing single page, responsive financial calculators for use by both end customers and financial advisers.</p>

                <p>The calculators were built using the following technologies:</p>

                <ul>
                    <li>HTML5</li>
                    <li>CSS/SASS</li>
                    <li>Javascript/jQuery</li>
                    <li>OO Javascript</li>
                    <li>JSON</li>
                    <li>Highcharts</li>
                    <li>jQuery validator</li>
                    <li>Google Maps API</li>
                    <li>Bootstrap 3</li>
                </ul>

                <p>The calculations within the tools were created by in-house actuaries and converted into pure javascript calculations. Certain data such as calculation variables, default values and textual information were stored in JSON files to enable easy updating. Most calculators were updated every April in line with tax/legislation changes.</p>

                <p>Most calculators also included custom print options to allow the printing or downloading of reports based on the calculator outputs.</p>

                <p>The live calculators can be viewed at <a href="https://www.pru.co.uk/tools-calculators/" target="_blank">https://www.pru.co.uk/tools-calculators/</a> &amp; <a href="https://www.pruadviser.co.uk/tools-calculators/" target="_blank">https://www.pruadviser.co.uk/tools-calculators/</a>. The websites are out of my control and may be changed.</p>

                <p>Below are shown a series of screenshots of some of the calculators (Click images to view full size)</p>

                <div className="portfolio thumbnails">
                    <a href="/images/portfolio/prudential/annual_allowance_calculator.png" target="_blank"><img src="/images/portfolio/prudential/annual_allowance_calculator.png" alt="Annual allowance calculator" /></a>
                    <a href="/images/portfolio/prudential/app_generation_tool.png" target="_blank"><img src="/images/portfolio/prudential/app_generation_tool.png" alt="App generation tool" /></a>
                    <a href="/images/portfolio/prudential/available_funds_01.png" target="_blank"><img src="/images/portfolio/prudential/available_funds_01.png" alt="Available funds #1" /></a>
                    <a href="/images/portfolio/prudential/available_funds_02.png" target="_blank"><img src="/images/portfolio/prudential/available_funds_02.png" alt="Available funds #2" /></a>
                    <a href="/images/portfolio/prudential/client_finder_01.png" target="_blank"><img src="/images/portfolio/prudential/client_finder_01.png" alt="Client finder #1" /></a>
                    <a href="/images/portfolio/prudential/client_finder_02.png" target="_blank"><img src="/images/portfolio/prudential/client_finder_02.png" alt="Client finder #2" /></a>
                    <a href="/images/portfolio/prudential/client_finder_03.png" target="_blank"><img src="/images/portfolio/prudential/client_finder_03.png" alt="Client finder #3" /></a>
                    <a href="/images/portfolio/prudential/collectives_vs_bonds_01.png" target="_blank"><img src="/images/portfolio/prudential/collectives_vs_bonds_01.png" alt="Collectives vs bonds" /></a>
                    <a href="/images/portfolio/prudential/discounted_gift_trust.png" target="_blank"><img src="/images/portfolio/prudential/discounted_gift_trust.png" alt="Discounted gift trust" /></a>
                    <a href="/images/portfolio/prudential/emergency_tax_tool.png" target="_blank"><img src="/images/portfolio/prudential/emergency_tax_tool.png" alt="Emergency tax tool #1" /></a>
                    <a href="/images/portfolio/prudential/emergency_tax_tool_02.png" target="_blank"><img src="/images/portfolio/prudential/emergency_tax_tool_02.png" alt="Emergency tax tool #2" /></a>
                    <a href="/images/portfolio/prudential/extracting_company_profits.png" target="_blank"><img src="/images/portfolio/prudential/extracting_company_profits.png" alt="Extracting company profits" /></a>
                    <a href="/images/portfolio/prudential/full_bond_calculator_01.png" target="_blank"><img src="/images/portfolio/prudential/full_bond_calculator_01.png" alt="Full bond calculator #1" /></a>
                    <a href="/images/portfolio/prudential/full_bond_calculator_02.png" target="_blank"><img src="/images/portfolio/prudential/full_bond_calculator_02.png" alt="Full bond calculator #2" /></a>
                    <a href="/images/portfolio/prudential/investment_calculator.png" target="_blank"><img src="/images/portfolio/prudential/investment_calculator.png" alt="Investment calculator" /></a>
                    <a href="/images/portfolio/prudential/isa_cost_calculator_01.png" target="_blank"><img src="/images/portfolio/prudential/isa_cost_calculator_01.png" alt="ISA cost calculator #1" /></a>
                    <a href="/images/portfolio/prudential/isa_cost_calculator_02.png" target="_blank"><img src="/images/portfolio/prudential/isa_cost_calculator_02.png" alt="ISA cost calculator #2" /></a>
                    <a href="/images/portfolio/prudential/retirement_modeller_01.png" target="_blank"><img src="/images/portfolio/prudential/retirement_modeller_01.png" alt="Retirement modeller #1" /></a>
                    <a href="/images/portfolio/prudential/retirement_modeller_02.png" target="_blank"><img src="/images/portfolio/prudential/retirement_modeller_02.png" alt="Retirement modeller #2" /></a>
                    <a href="/images/portfolio/prudential/trust_and_application_tool.png" target="_blank"><img src="/images/portfolio/prudential/trust_and_application_tool.png" alt="Trust and application tool" /></a>
                </div>

                <Link to="/cv" className="leftArrow">Back to CV</Link>
            </>
        )
    }
}

export default Prudential;
