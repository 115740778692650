// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import {Link} from 'react-router-dom';
// -----------------------------------------------------------------------------

class Cv extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <h1>CV</h1>

                <h2 className="personal">Personal Details</h2>

                <p>
                    <strong>Name</strong>: Patrick John Dobson<br />
                    <strong>Email</strong>: <a href="mailto:pat@patrickdobson.co.uk?subject=Contact%20from%20PatrickDobson.co.uk" className="inline">pat@patrickdobson.co.uk</a><br />
                    <strong>Mobile</strong>: (07833) 184038<br />
                    <strong>Marital Status</strong>: Married</p>

                <h2>Qualifications</h2>

                <ul>
                    <li>Bsc technology (Open University)</li>
                    <li>HNC Multimedia Information Technology (University of Gloucestershire)</li>
                </ul>

                <h2>Skills</h2>

                <p>Highly skilled and qualified web developer with ten years experience in design and development working with customers both large and small. A portfolio of my websites can be found in the portfolio section of this site.</p>

                <div className="hList">
                    <p>Major:</p>
                    <ul>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>SASS / SCSS</li>
                        <li>Javascript</li>
                        <li>jQuery</li>
                        <li>PHP</li>
                        <li>SQL/MySQL</li>
                        <li>jQuery validator</li>
                        <li>Cross browser compliance</li>
                        <li>Customer Facing Skills</li>

                    </ul>
                </div>

                <div className="hList">
                    <p>Minor:</p>
                    <ul>
                        <li>AJAX</li>
                        <li>SEO</li>
                        <li>JSON</li>
                        <li>Photoshop</li>
                        <li>OO Javascript</li>
                        <li>Responsive / mobile</li>
                        <li>Jest</li>
                        <li>React</li>
                        <li>Wordpress</li>
                        <li>JSP</li>
                        <li>ES6</li>
                        <li>Handlebars</li>
                        <li>Highcharts</li>
                    </ul>
                </div>

                <div className="hList">
                    <p>Strong awareness of:</p>
                    <ul>
                        <li>Accessibility (DDA etc.)</li>
                        <li>Usability</li>
                        <li>XML</li>
                        <li>SVN</li>
                        <li>GIT</li>
                        <li>Agile</li>
                        <li>Relational Database Design</li>
                        <li>Eclipse</li>
                        <li>Bootstrap (2/3)</li>
                        <li>SFCC</li>
                    </ul>
                </div>

                <p className="cBoth"><a href="https://github.com/PatDobson1" target="_blank">Click here to view my GitHub Repos</a></p>

                <h2 className="cBoth">Experience</h2>

                {/* --- Wunderman / Halfords  --- */}
                <a name="halfords"></a>
                <h4><span>Wunderman Thompson / Halfords</span>Contract - June 2022 - March 2023 - Front end developer - 100% remote</h4>

                <p>Contracting with Wunderman Thompson / Halfords as a front-end developer.</p>
                <p>Halfords are a UK wide retailer of car and bicycle products</p>
                <p>The role was front end development using CSS (SCSS), HTML, JS, jQuery, handlebars and Jest in a Salesforce (SFCC) environment. In my role as a front end developer, I worked closely with the (Javascript) back end developers.</p>
                <p>The team worked in an agile environment using MS Teams and Slack for communication and BitBucket/sourcetree for source integrity.</p>
                <p>This contract was 100% remote.</p>
                <p>Skillset:</p>
                <ul className="listInline">
                    <li>HTML5</li>
                    <li>CSS</li>
                    <li>SCSS</li>
                    <li>Javascript</li>
                    <li>jQuery</li>
                    <li>Jest</li>
                    <li>Handlebars</li>
                </ul>

                <p><a href="https://www.halfords.com/" className="rightArrow" target="_blank">Halfords</a></p>

                {/* --- Intechnica / Pets  --- */}
                <a name="pets"></a>
                <h4><span>Intechnica / Pets at home</span>Contract - January 2021 - April 2022 - Front end developer - 100% remote</h4>

                <p>Contracting with Intechnica / Pets at home as a front-end developer.</p>
                <p>Pets at home is a nationally known pet supplies retailer with a large ecommerce website.</p>
                <p>The role was front end development using CSS (SCSS), HTML, JS, jQuery & JSP in a webSphere environment. In my role as a front end developer, I worked closely with the (Java) back end developer.</p>
                <p>The team worked in an agile environment using MS Teams and Slack for communication and GIT/sourcetree for source integrity.</p>
                <p>This contract was 100% remote.</p>


                <p>Skillset:</p>

                <ul className="listInline">
                    <li>HTML5</li>
                    <li>CSS</li>
                    <li>SCSS</li>
                    <li>Javascript</li>
                    <li>jQuery</li>
                    <li>JSP</li>
                </ul>

                <p><a href="https://www.petsathome.com" className="rightArrow" target="_blank">Pets at home</a></p>

                {/* --- MAPS  --- */}
                <a name="maps"></a>
                <h4><span>NTT Data / MoneyHelper</span>Contract - November 2020 - January 2021 - Front end developer - 100% remote</h4>

                <p>Contracting with NTT Data / MoneyHelper as a front-end developer.</p>

                <p>The MoneyHelper website is a new Government run service providing help and support in financial matters.</p>

                <p>The project involved reskinning legacy calculators from the Money &amp; Pensions Service to match the new styling of the new government MoneyHelper website.</p>

                <p>The role was front-end development using CSS (SCSS) HTML, JS in a Ruby on Rails environment. In my role as a front end developer I worked closely with backend (Ruby) developers to develop the UI for the calculators for the website. This involved working in an agile environment using Azure, gitHub &amp; MS Teams for communication.</p>

                <p>This contract was 100% remote.</p>

                <p>Skillset:</p>

                <ul className="listInline">
                    <li>HTML5</li>
                    <li>CSS</li>
                    <li>SCSS</li>
                    <li>Javascript</li>
                </ul>

                <p>Exposure to:</p>

                <ul className="listInline">
                    <li>Ruby on rails</li>
                    <li>Ubuntu</li>
                    <li>MySQL</li>
                    <li>Bundler/Bowndler</li>
                    <li>React</li>
                    <li>Miro</li>
                    <li>Figma</li>
                    <li>Azure</li>
                </ul>

                <p>
                    <a href="https://www.moneyadviceservice.org.uk" className="rightArrow" target="_blank">the Money Advice Service (existing site)</a>
                    (<em>Due to be live Q2-Q3 2021</em>)
                </p>

                {/* --- PortalTech / IBA  --- */}
                <a name="iba"></a>
                <h4><span>PortalTech Reply / International Bar Association</span>Contract - June 2020 - November 2020 - Front end developer - 100% remote</h4>

                <p>Contracting with PortalTech Reply / International Bar Association as a front-end developer.</p>

                <p>The IBA is the leading organisation for international legal practitioners, bar associations and law societies.</p>

                <p>The role was front-end development using javascript, CSS (SCSS) HTML, JS, jQuery & JSP in a hybris environment. In my role as a front end developer I worked closely with backend (java) developers to develop the UI for the website. This involved working in an agile environment using JIRA, bitbucket & Skype for communication. I implemented gulp processing of JS and SCSS. I also communicated with internal APIs to create product displays with filtering, search and pagination</p>

                <p>This contract was 100% remote.</p>

                <p>Skillset:</p>

                <ul className="listInline">
                    <li>HTML5</li>
                    <li>CSS</li>
                    <li>SCSS</li>
                    <li>Javascript</li>
                    <li>jQuery</li>
                    <li>JSP</li>
                    <li>Gulp</li>
                </ul>

                <p>
                    <a href="https://www.ibanet.org" className="rightArrow" target="_blank">International Bar Association</a>
                    (<em>Due to be live late 2020/early 2021</em>)
                </p>

                {/* --- Wunderman Thompson / Selfridges  --- */}
                <a name="selfridges"></a>
                <h4><span>Wunderman Thompson / Selfridges</span>Contract - March 2020 - April 2020 - Front end developer</h4>

                <p>Contracting with Wunderman Thompson / Selfridges as a front-end developer.<br />
                Wunderman Thompson supply e-commerce solutions to various large retail companies.<br />
                The role was front-end development using javascript, css (scss) and html in an AEM environment. In my role as front-end developer working in an agile environment I handled various tasks including integrating the AEM front end with a React app which replicated the user menu and basket/wishlist functionality. This involved making calls to an internal API and processing the returned data.<br />
                All work was built using Maven/Gulp/Webpack and tracked using command line GIT.</p>

                <p><em>Please note that this contract was cut short due to Coronavirus - initial contract length 12 months.</em></p>

                <p>Skillset:</p>

                <ul className="listInline">
                    <li>HTML5</li>
                    <li>CSS</li>
                    <li>SCSS</li>
                    <li>Javascript</li>
                    <li>jQuery</li>
                </ul>

                <p>&nbsp;</p>

                {/* ---- Glue / BMW ---------------------- --- */}
                <a name="BMW"></a>
                <h4><span>Open Reply / BMW</span>Contract - November 2019 - December 2019 - Front end developer</h4>

                <p>Contracting with BMW / Open Reply in London as a front-end developer.<br />
                Glue reply supply specialist consultant services to many large companies around the world.<br />
                This role was a short ‘pilot’ contract to provide estimates for a larger contract to be negotiated and tendered in 2010.
                BMW has a large number of internal websites for such purposes as auditing, documentation and employee directories.
                These websites were written around 15-20 years ago and only work in Internet Explorer due to inline CSS &amp; Javascript.
                BMW now have a policy that all employees use Google Chrome. The project was to remove all inline CSS &amp; Javascript
                into external files and to update the page layouts from table based layouts to div based.</p>

                <p>Skillset: </p>

                <ul className="listInline">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JavaScript/jQuery</li>
                    <li>Atom (IDE)</li>
                    <li>JSP</li>
                    <li>GIT (command line)</li>
                    <li><em>Basic</em> Java</li>
                </ul>

                <p>&nbsp;</p>

                {/* ---- Open / BBR ---------------------- --- */}
                <a name="BBR"></a>
                <h4><span>Open Reply / Berry Bros. &amp; Rudd</span>Contract - February 2019 - October 2019 - Front end Developer</h4>

                <p>Contracting with Berry Bros. &amp; Rudd / Open Reply in London as a front-end developer.<br />
                Open reply supply specialist consultant services to many large companies around the world.<br />
                Berry Bros are one of the countries most established wine and spirit merchants.</p>
                <p>In my role as a front-end developer is was part of an agile team creating a redesigned website for the client. The website is based around a Hybris server so front end development also consisted of using .jsp (Java Server Pages) as well as HTML, CSS, Javascript &amp; jQuery. I also worked closely with a designer and business representatives to deliver pixel perfect implementations of signed off designs. The project used GIT, integrated into JIRA as a source integrity tool.</p>

                <a href="https://www.bbr.com/" className="rightArrow" target="_blank">Berry Bros. &amp; Rudd</a>

                <p>Skillset: </p>

                <ul className="listInline">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JavaScript/jQuery</li>
                    <li>Atom (IDE)</li>
                    <li>Responsive development</li>
                    <li>Agile / JIRA</li>
                    <li>GIT (SourceTree)</li>
                    <li>JSP</li>
                </ul>

                <p>&nbsp;</p>
                {/* -------------------------------------- --- */}

                {/* ---- MVC ---------------------- --- */}
                <a name="MVC"></a>
                <h4><span>MyVouchercodes / GoCompare</span>Contract - July 2019 - January 2019 - Front end Developer</h4>

                <p>Contracting with <a href="https://www.myvouchercodes.co.uk/" target="_blank">MyVouchercodes / GoCompare</a> in London as a front-end developer.</p>
                <p>MyVouchercodes are a leading website specialising in publication of discount vouchers for public consumption. In my role as front-end developer I was a part of an agile team tasked with maintaining the website, fixing bugs and developing new features. The MyVouchercodes website was developed using a bespoke PHP CMS and front-end work consisted of SCSS, jQuery & Javascript. At times I also developed some object orientated PHP. MyVouchercodes operates am agile environment which included daily stand-up meetings, sprints, sprint planning, backlog refinement and ‘Three amigos’ planning.</p>

                <a href="https://www.myvouchercodes.co.uk/" className="rightArrow" target="_blank">MyVouchercodes</a>

                <p>Skillset: </p>

                <ul className="listInline">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>SASS/SCSS</li>
                    <li>JavaScript/jQuery</li>
                    <li>PHP Storm</li>
                    <li>Responsive development</li>
                    <li>Grunt</li>
                    <li>Agile / JIRA</li>
                </ul>

                <p>&nbsp;</p>
                {/* -------------------------------------- --- */}

                {/* ---- Prudential ---------------------- --- */}
                <a name="Prudential"></a>
                <h4><span>Prudential</span>Contract - November 2013 - June 2018 - Front end Developer</h4>

                <p>Contracting with <a href="http://www.pru.co.uk/" target="_blank">Prudential</a> in Reading as a front end developer.</p>
                <p>Prudential are a very well established provider of pensions and financial products.</p>
                <p>The contract consisted of creating financial calculators for use by the public and financial advisers. The calculators were developed using HTML, Javascript and jQuery and were designed to be cached in the browser enabling them to be used offline. All the calculators were fully responsive from large monitor size down to mobile phone (320px wide). All work was cross browser compatible and was tested in IE7-11, Firefox, Chrome, Safari, Android &amp; IOS</p>

                <Link to="/prudential" className="rightArrow">View some of my Prudential work</Link>

                <p>Skillset: </p>

                <ul className="listInline">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>SASS/SCSS</li>
                    <li>JavaScript/jQuery/OO Javascript</li>
                    <li>Bootstrap 2/3</li>
                    <li>Highcharts</li>
                    <li>jQuery validator</li>
                    <li>Eclipse</li>
                    <li>Responsive development</li>
                    <li>Gulp</li>
                </ul>

                <p>&nbsp;</p>
                {/* -------------------------------------- --- */}

                {/* ---- Line ---------------------------- --- */}
                <a name="Line"></a>
                <h4><span>Line communications</span>Contract - August 2013 - November 2013 - Lead front end Developer</h4>

                <p>Contracting with <a href="http://www.line.co.uk/" target="_blank">Line Communications</a> in London as a front end web developer.</p>
                <p>Line communications are a leading provider of e-learning based in Paddington in London.</p>
                <p>The contract consisted of using their existing responsive e-learning frameworks and creating a 'new style' of e-learning using modern HTML &amp; CSS techniques such as parallax scrolling and CSS transitions. As part of the contract I worked on projects for British Airways and Roche. All work was cross browser compatible and tested in IE8, IE9, IE10, Firefox, Chrome, Safari &amp; iPad.</p>


                <p>Skillset: </p>

                <ul className="listInline">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>SASS/SCSS</li>
                    <li>JavaScript/jQuery</li>
                    <li>Bootstrap</li>
                </ul>

                <p>&nbsp;</p>
                {/* -------------------------------------- --- */}

                {/* ---- Wex ----------------------------- --- */}
                <a name="Wex"></a>
                <h4><span>Wex photographic</span>Contract - May 2013 - July 2013 - Lead front end Developer</h4>

                <p>Contracting with <a href="http://www.wexphotographic.com/" target="_blank">Wex Photographic</a> in London as a lead front end web developer.</p>
                <p>The contract consisted of an entire rebuild of the existing website before handover to a third party development company for integration into an EpiServer CMS.</p>
                <p>During the contract I worked closely with the graphic designer and the company representatives to translate Photoshop documents into a working prototype of the site. Twitter bootstrap was used as a framework with interaction in jQuery/javascript. All the CSS was written with SASS/SCSS. The site was developed to be 'mobile friendly', with tablets displaying the desktop site (by management decision) and being optimised for smart phones by the use of media queries. All work was browser tested in Firefox, Chrome, Safari, IE10, IE9, IE8 & IE7 and was validated error free to W3C standards.</p>

                <p>Skillset: </p>

                <ul className="listInline">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>SASS/SCSS</li>
                    <li>JavaScript/jQuery</li>
                    <li>Photoshop</li>
                    <li>Bootstrap</li>
                </ul>

                <p>&nbsp;</p>
                {/* -------------------------------------- --- */}

                {/* ---- ICAEW --------------------------- --- */}
                <a name="icaew"></a>
                <h4><span>Institute of chartered accountants England &amp; Wales</span>Contract - January 2013 - April 2013 - UI Developer</h4>

                <p>Contracting with the <a href="http://www.icaew.com" target="_blank">Institute of chartered accountants</a> in Milton Keynes as a front end/UI web developer.<br />
                The contract consisted of a redevelopment of a paper based training records system into a fully functional web based application. The application was written in ASP.NET MVC. I was responsible for the UI/front end elements of the development. I worked from wire frames and brand guidelines and created the entire HTML, CSS, graphics/images and JavaScript interaction for the application. Application development was carried out using Visual Studio 2012 and TFS. I was part of a team using AGILE/SCRUM methodologies. All work was browser tested in Firefox, Chrome, IE9, IE8 &amp; IE7.</p>

                <p>Skillset: </p>

                <ul className="listInline">
                    <li>HTML</li>
                    <li>CSS2 / CSS3</li>
                    <li>SASS/SCSS</li>
                    <li>JavaScript/jQuery</li>
                    <li>Photoshop</li>
                </ul>

                <p>&nbsp;</p>
                {/* -------------------------------------- --- */}

                {/* ---- Dyson --------------------------- --- */}
                <a name="dyson"></a>
                <h4><span>Dyson</span>Contract - October 2012 - December 2012 - Front End Developer</h4>

                <p>Contracting with <a href="http://www.dyson.co.uk" target="_blank">Dyson</a> in Malmesbury as a front end web developer.<br />Dyson have 94+ multi-territory sites both promoting and selling their products around the world. Dyson web development operate with an AGILE/SCRUM environment. The contract consisted of working with backend developers, creative staff and project managers to create and maintain all aspects of the websites. Dyson have their own bespoke CMS which was used to serve regional variations to the websites (such as translated content). All projects were created and checked for operation in the following browsers: FireFox (Windows &amp; Mac), Chrome, Safari (Windows &amp; Mac), IE9, IE8, IE7, IE6. All projects were validated against WAI AA, XHTML(strict) &amp; CSS2. Source integrity was controlled with Microsoft Source Safe. As part of this contract I was also working with ASP (classic) and XSLT.</p>

                <Link to="/dyson" className="rightArrow">View some of my Dyson work</Link>


                <p>Skillset:</p>

                <ul className="listInline">
                    <li>XHTML</li>
                    <li>CSS</li>
                    <li>Photoshop</li>
                </ul>

                <p>&nbsp;</p>

                {/* ---- npower -------------------------- --- */}
                <h4><span>npower</span>Contract - June 2012 - October 2012 - Front End Developer</h4>

                <p>Contracting with <a href="http://www.npower.com" target="_blank">npower</a> in Worcester as a senior front end web developer.<br />npower are a national energy supplier who supply electricity and gas as well as home cover. Their websites cover residential properties as well as small, medium and large business customers.<br />Their websites are written with a combination of ASP.NET and 'plain' HTML pages via the Oracle content server CMS.<br />All work was cross browser compatible ( FireFox, Chrome, Safari, IE9-8-7). During my time efforts where being made to make the sites mobile friendly using 'responsive' design and media queries.</p>

                <p>Skillset:</p>

                <ul className="listInline">
                    <li>HTML 4/5</li>
                    <li>CSS 2/3</li>
                    <li>Javascript / jQuery</li>
                    <li>Photoshop</li>
                    <li>Site studio designer</li>
                </ul>

                <p>&nbsp;</p>

                {/* ---- Dyson --------------------------- --- */}
                <h4><span>Dyson</span>Contract - June 2011 - June 2012 - Front End Developer</h4>

                <p><em>See above for Dyson description</em></p>

                <Link to="/dyson" className="rightArrow">View some of my Dyson work</Link>

                <p>&nbsp;</p>

                {/* ---- VerseOne ------------------------ --- */}
                <a name="verseOne"></a>
                <h4><span>Verse one</span>Contract - March - June 2011 - Front End Developer</h4>

                <p>Contracting with <a href="http://www.verseone.com" target="_blank">Verse One</a> in Woking as a front end web developer.<br />
                Verse One create easy to use and highly accessible Content Management Systems for a wide range of clients. The contract entailed updating older installations of the CMS to the latest version as well as implementing brand new installations. Upgrades consisted of creating pixel-perfect copies of old sites using a new code base. New implementations required working from a Photoshop file. Once finished, all implementations were tested and amended for FireFox, Chrome, IE9, IE8, IE7 &amp; IE6 (graceful degradation).</p>

                <p>Skillset:</p>

                <ul className="listInline">
                    <li>XHTML</li>
                    <li>CSS</li>
                    <li>SQL &amp; MySQL</li>
                    <li>Coldfusion 8</li>
                    <li>Photoshop</li>
                </ul>

                <p><Link to="/verseOne" className="rightArrow">View some of my Verse One work</Link></p>

                <p className="cLeft">&nbsp;</p>

                <h4>Freelance - 2006  2010</h4>

                <p>In my role as a freelance web developer, I have been involved in the entire project lifecycle from initial discussions with customers, requirement gathering, planning and design &amp; development of entire websites and provision and ongoing maintenance of hosting &amp; email. A portfolio of my websites can be found in the <Link to="/portfolio" className="inline">portfolio section of this site.</Link><br />I have a great talent for being able to de-mystify the issues concerning technical concepts when discussing a customers needs.</p>

                <h4>Developer  Serco Home Affairs	2002 - 2006</h4>

                <p>In my role as senior developer, and part of the web development team, I designed, built and implemented web-based solutions for several police forces as well as national law enforcement and Government agencies. I worked as a member of a multi-disciplinary project team.</p>

                <ul>
                    <li>I designed and developed a Public facing website for a national specialist law enforcement agency, entirely in Flash.</li>
                    <li>Implemented an extranet for a national specialist law enforcement agency, written in Coldfusion with Flash and 3D components.</li>
                    <li>Designed and developed a Flash based computer based training package for a national law enforcement agency.</li>
                    <li>Implemented a third party content management system (NQContent) for both a law enforcement agency and a government department.</li>
                    <li>Designed marketing materials for a government agency.</li>
                    <li>Developed an internal intranet for Serco Home Affairs, using Coldfusion, Flash Remoting, JavaScript and CSS.</li>
                    <li>Consultation with customers for design matters, problem solving, training and development of project specifications.</li>
                    <li>Worked as part of a large team creating an internal system for a major law enforcement agency. In this role, I was part of a smaller team redesigning the front end of the system and re coding for accessibility. The entire project was secured using MKS source integrity software.</li>
                </ul>

                <h4>Previous Work Experience	Serco 1996  2002 &amp; Pre 1996</h4>

                <ul>
                    <li>FPGA design for Serco Home Affairs.</li>
                    <li>Electronic prototyping for Serco Home Affairs &amp; OSPL</li>
                </ul>

                <h4>Other qualifications/experience:</h4>

                <ul>
                    <li>Fast track to Coldfusion MX (Macromedia)</li>
                    <li>Fast track to Dreamweaver MX (Macromedia)</li>
                    <li>Macromedia Flash MX Actionscript (Macromedia)</li>
                    <li>Internal TickIT auditing</li>
                    <li>ISO 9001 internal auditing</li>
                    <li>Valuing Diversity Training</li>
                    <li>Professional Trainer Certification</li>
                </ul>

                <h4>Freelance Work Samples</h4>

                <ul className="samples">
                    <li><a href="https://project1.patrickdobson.co.uk/" className="inline aBold" target="_blank">project1.patrickdobson.co.uk</a> / <a href="https://project2.patrickdobson.co.uk/" className="inline aBold" target="_blank">project2.patrickdobson.co.uk</a> / <a href="https://project3.patrickdobson.co.uk/" className="inline aBold" target="_blank">project3.patrickdobson.co.uk</a><br />A series of test project written in React with HTML5, CSS, SCSS &amp; PHP</li>
                    <li><a href="https://github.com/PatDobson1" className="inline aBold" target="_blank">github.com/PatDobson1</a></li>
                    <li><a href="https://www.ynnisirgar.org/" className="inline aBold" target="_blank">www.ynnisirgar.org</a><br />A bilingual site in Welsh/English - HTML5, CSS, Javascript, jQuery, PHP</li>
                    <li><a href="https://ttalkltd.co.uk/" className="inline aBold" target="_blank">www.ttalkltd.co.uk</a><br />HTML5, CSS, Javascript, React</li>
                    <li><a href="https://www.broadstonerarebreeds.co.uk/" className="inline aBold" target="_blank">www.broadstonerarebreeds.co.uk</a><br />A site for Broadstone Rare Breeds - written in React with react-router, react-helmet, HTML5, CSS, SCSS &amp; PHP. Includes integration into the Etsy API for product displays.</li>
                    <li><a href="https://www.devonandcornwallflockbook.co.uk" className="inline aBold" target="_blank">www.devonandcornwallflockbook.co.uk</a><br />HTML5, CSS, Javascript, jQuery, Wordpress, PHP</li>
                    <li><a href="http://www.journeyman-services.com" className="inline aBold" target="_blank">www.journeyman-services.com</a><br />HTML5, CSS, Photoshop, AJAX, DOM Scripting, Javascript, jQuery, PHP, SQL, MySQL</li>
                    <li><a href="http://www.eco-camping.co.uk" className="inline aBold" target="_blank">www.eco-camping.co.uk</a><br />HTML5, CSS, Photoshop</li>
                    <li><a href="http://www.northlodgewales.co.uk" className="inline aBold" target="_blank">www.northlodgewales.co.uk</a><br />HTML5, CSS, Photoshop</li>
                    <li><a href="http://www.parkthatbike.info" className="inline aBold" target="_blank">www.parkthatbike.com</a><br />HTML5, CSS, javascript, jQuery, Wordpress API, PHP</li>
                </ul>

                <div id="cvDownload">
                    <h4>Download my CV</h4>
                    <a href="/media/CV-Patrick-Dobson.pdf" className="pdf" target="_blank">PDF</a>
                    <a href="/media/CV-Patrick-Dobson.docx" className="word" target="_blank">Word</a>
                </div>
            </>
        )
    }
}

export default Cv;
