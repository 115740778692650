// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import {Link} from 'react-router-dom';
// -----------------------------------------------------------------------------

class Dyson extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <h1>Dyson portfolio</h1>

                <p>Due to the nature of my work at Dyson it is difficult to provide URLs to samples. Many aspects of my work were concerned with promotional material which, by its very nature is removed when the promotion is finished. Shown below are a series of static screen shots of some of my work. Please click a thumbnail image to view the full image.</p>

                <Link to="/cv" className="leftArrow">Back to CV</Link>

                <div className="portfolio">
                    <a href="/images/portfolio/dyson/airblade_01.jpg" target="_blank"><img src="/images/portfolio/dyson/airblade_01_th.jpg" alt="Airblade" /></a>
                    <a href="/images/portfolio/dyson/ball_01.jpg" target="_blank"><img src="/images/portfolio/dyson/ball_01_th.jpg" alt="Ball technology" /></a>
                    <a href="/images/portfolio/dyson/ball_02.jpg" target="_blank"><img src="/images/portfolio/dyson/ball_02_th.jpg" alt="Ball technology" /></a>
                    <a href="/images/portfolio/dyson/ball_03.jpg" target="_blank"><img src="/images/portfolio/dyson/ball_03_th.jpg" alt="Ball technology" /></a>
                    <a href="/images/portfolio/dyson/ball_04.jpg" target="_blank"><img src="/images/portfolio/dyson/ball_04_th.jpg" alt="Ball technology" /></a>
                    <a href="/images/portfolio/dyson/carbonFibre_01.jpg" target="_blank"><img src="/images/portfolio/dyson/carbonFibre_01_th.jpg" alt="Carbon Fibre Technology" /></a>
                    <a href="/images/portfolio/dyson/christmas_01.jpg" target="_blank"><img src="/images/portfolio/dyson/christmas_01_th.jpg" alt="Christmas promotion" /></a>
                    <a href="/images/portfolio/dyson/christmas_02.jpg" target="_blank"><img src="/images/portfolio/dyson/christmas_02_th.jpg" alt="Christmas promotion" /></a>
                    <a href="/images/portfolio/dyson/christmas_03.jpg" target="_blank"><img src="/images/portfolio/dyson/christmas_03_th.jpg" alt="Christmas promotion" /></a>
                </div>

                <Link to="/cv" className="leftArrow">Back to CV</Link>
            </>
        )
    }
}

export default Dyson;
